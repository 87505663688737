import React, {
  useEffect,
  useState,
} from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonCardSubtitle,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setRecentCheckins } from '../lib/features/listingsSlice';
import {
  loadCheckins,
  loadListingListListings,
  loadSips,
} from '../lib/api-utils';
import ListingCheckins from './ListingCheckins';
import SipList from './SipList';
import UserLists from './UserLists';

const ListingUserContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const listing = useSelector((state) => state.listings.selectedListing);
  const checkins = useSelector((state) => state.listings.recentCheckins);

  const [lists, setLists] = useState([]);
  const [sips, setSips] = useState([]);

  const [cardContent, setCardContent] = useState('checkins');

  useEffect(() => {
    const onLoadCheckins = async () => {
      const chckns = await loadCheckins(listing.id);
      dispatch(setRecentCheckins(chckns));
    };

    const onLoadLists = async () => {
      const listListings = await loadListingListListings(listing.id);
      const lsts = listListings.map((item) => item.listingList);
      setLists(lsts);
    };

    const onLoadSips = async () => {
      const sps = await loadSips(listing.id);
      setSips(sps);
    };

    if (auth.authenticated) {
      onLoadLists();
      onLoadCheckins();
      onLoadSips();
    }
  }, [listing, auth.authenticated]);

  const onListsClick = async (list) => {
    history.push(`/me/lists/${list.id}`);
  };

  const getCheckinSubtitle = (ckns) => {
    if (ckns.length === 0) {
      return 'You\'ve yet to check in here';
    }
    return `You've been here ${ckns.length} ${ckns.length === 1 ? 'time' : 'times'}.`;
  };

  const getListsSubtitle = (lst) => {
    if (lst.length === 0) {
      return 'You\'ve yet to include this listing in any lists';
    }

    return `This listing appears in ${lst.length} of your lists.`;
  };
  const getSipsSubtitle = (sps) => {
    if (sps.length === 0) {
      return 'You\'ve yet to enjoy sips here';
    }

    return `You've had ${sps?.length} ${sps?.length === 1 ? 'sip' : 'sips'} at this listing.`;
  };

  return (
    <>
      {(auth.authenticated)
        && (
          <IonCard>
            <IonCardHeader className="ion-no-padding">
              <IonSegment
                value={cardContent}
                scrollable
                onIonChange={(e) => setCardContent(e.detail.value)}
                style={{ display: 'flex', borderRadius: 0, justifyContent: 'center' }}
              >
                <IonSegmentButton value="checkins">
                  <IonLabel>
                    <h2><b>Check-Ins</b></h2>
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="lists">
                  <IonLabel>
                    <h2><b>Lists</b></h2>
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="sips">
                  <IonLabel>
                    <h2><b>Sips</b></h2>
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              {cardContent === 'checkins'
                && (
                  <ScrollableContent
                    subtitle={getCheckinSubtitle(checkins)}
                  >
                    <ListingCheckins checkins={checkins} />
                  </ScrollableContent>
                )}
              {cardContent === 'lists'
                && (
                  <ScrollableContent
                    subtitle={getListsSubtitle(lists)}
                  >
                    <UserLists lists={lists} onListsClick={onListsClick} atListing />
                  </ScrollableContent>
                )}
              {cardContent === 'sips'
                && (
                  <ScrollableContent
                    subtitle={getSipsSubtitle(sips)}
                  >
                    <SipList sips={sips} updateListingSips={setSips} atListing />
                  </ScrollableContent>
                )}
            </IonCardContent>
          </IonCard>
        )}
    </>
  );
};

const ScrollableContent = (props) => {
  const { subtitle, children } = props;
  return (
    <>
      <IonCardHeader style={{ paddingBottom: '6px', paddingLeft: '16px' }}>
        {subtitle
          && (
            <IonCardSubtitle>
              <span>
                {subtitle}
              </span>
            </IonCardSubtitle>
          )}
      </IonCardHeader>
      <IonCardContent style={{ maxHeight: '300px', overflow: 'scroll' }} className="ion-no-padding">
        {children}
      </IonCardContent>
    </>
  );
};

export default ListingUserContent;
